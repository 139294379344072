<script setup lang="ts"></script>

<template>
  <div class="layout-preloader-container">
    <div class="layout-preloader">
      <span></span>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
