import { api } from '@repo/core/config';
import { FinancialYearOverview } from '@repo/core/models';

export const useFinancialYearOverviewService = () => {
  const get = async (parameters?: string): Promise<FinancialYearOverview> => {
    parameters = parameters || '';
    const options = { autoSave: true };
    try {
      const response = await api.get('financialYearOverview' + parameters, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get
  };
};
