import * as _ from 'lodash-es';
import { ref } from 'vue';
import { RibbonItem } from '@repo/core/models';

export const useDxRibbon = () => {
  const ribbon = ref<RibbonItem[]>([]);
  const ribbonItems = ref<RibbonItem[]>([]);

  const buildRibbon = (tabId: string, items: RibbonItem[]): RibbonItem[] => {
    ribbon.value = [];
    ribbonItems.value = items;

    switch (_.toUpper(tabId)) {
      case '2F2581F5-2DA9-43AE-B848-45F2C87D0CC0': //Client Setup Tab
        ribbon.value.push(
          //Client Setup Ribbon Tabs
          {
            title: 'Client Setup',
            items: [
              //Ribbon Groups
              // {
              //     title: 'Save', items:
              //     filterItems([
              //         'D3DC2DD5-D76C-49F8-A1B8-F9840E3ECE3D'
              //     ])
              // },
              {
                title: 'Synchronise',
                items: filterItems([
                  'ECFE2A65-318E-46F5-8911-D0F704293482',
                  '85E9F01B-65A8-42CD-A30A-ABD677F1C043',
                  'A44ED20D-2FC1-46B2-BC29-537B24451BEC',
                  'A7995114-8EA5-4034-AE7F-05B4277306BD'
                ])
              }
            ]
          }
        );
        break;
      case '8067D86A-802C-4CA7-AE9F-F7143252139E': //Tick Marks
        ribbon.value.push(
          //Client Setup Ribbon Tabs
          {
            title: 'Tick Marks',
            items: [
              //Ribbon Groups
              {
                title: 'Delete',
                items: filterItems(['2CCFC5FE-E54E-4247-B0C0-5D2D45C06ACF'])
              }
            ]
          }
        );
        break;
      case 'EC194F9B-8996-40AB-BFFA-740074E2CBD2': // Electronic Review
        ribbon.value.push({
          title: 'Electronic Review',
          items: [
            //Ribbon Groups
            {
              title: 'Notes Filter',
              items: filterItems(['06EC98A0-AB29-4BB0-A4B1-1333B4FE33E9', '29D633A4-2A6E-489E-BA9B-74869E03135F'])
            },
            {
              title: 'Electronic Review Filter',
              items: filterItems([
                '8719E8EE-5C62-4CD6-AD4A-33E26BC24314',
                '974628FE-959A-4943-9616-304EF4551C6C',
                'AAAD157B-7F2F-4340-B89A-101708B6A0ED',
                'F05A23E8-4B1F-4286-A9D0-73647318D4A1',
                '3D28C3D3-628F-4C20-9CE7-178D374D1D96',
                '37D2DD47-E6B1-471C-97B9-C706B45EA1AD',
                '56E01CB4-CBD5-498B-9FB3-8E6DB797E3A1',
                '60F4E1A6-41CF-478E-AC02-90B970F6AE64'
              ])
            },
            {
              title: 'Delete',
              items: filterItems(['6A7F241E-9737-4CF1-BB67-91DF00FFD643'])
            }
          ]
        });
        break;

      case '96AC9B7D-B799-470F-85EA-54C9DD02D113': //Working Trial Balance Tab
        ribbon.value.push(
          //Working Trial Balance Ribbon Tabs
          {
            title: 'Working Trial Balance',
            items: [
              //Ribbon Groups
              {
                title: 'Tax Year',
                items: _.orderBy(filterItems(['82D2D6D0-B1B5-4134-8377-92A5D8809BA5']), ['title', 'index'], ['desc'])
              },
              {
                title: 'Sync Prior',
                items: filterItems(['5D803AE9-E107-45E2-86E7-D421136303EF'])
              },
              {
                title: 'Periods',
                items: filterItems(['E047085E-F57F-4328-AB4B-29D25D887DA4'])
              },
              {
                title: 'Quick Assign',
                items: filterItems([
                  'E286A5CF-E260-4EB3-A0E7-93F4C8E3CA55',
                  'A76D357F-4BC1-4D79-857F-56C8F571C7AE',
                  'E4ACB577-5D1C-43A1-9E9B-4F9868BE9479'
                ])
              },
              {
                title: 'Direct Imports',
                items: filterItems([
                  '1e2f654a-7079-4624-96c4-96c64519f9b8',
                  '141ED04F-7B27-41D6-80E8-F1C54DF295AB',
                  'e48824ad-1cb4-4192-a317-f6bb15850bf0',
                  '28C8FE99-B6D9-49F5-847E-405F63973513'
                ])
              },
              {
                title: 'Import',
                items: filterItems([
                  'F9CE6ADF-63AF-4CCE-8203-399F9098D6FC',
                  '9C8EA79C-4200-4B09-AE43-DCE19FC45A74',
                  '60194653-4A0F-4DA7-B74A-63F2144C4B06',
                  '2CE888FA-897B-49D4-B66A-9F3EE5289AF1'
                ])
              },
              {
                title: 'Export',
                items: filterItems(['3094DA6D-551C-40C7-AC8E-5F58BDFEB737'])
              },
              {
                title: 'Reports',
                items: filterItems(['C029B690-6E78-4134-8B7B-A8888BB151F5'])
              },
              {
                title: 'General Ledger',
                items: filterItems(['E6A5200B-9EE0-4E5F-8EA6-FE31C885122A'])
              },
              {
                title: 'Consolidation',
                items: filterItems(['A59BBB32-A2AE-4E08-8778-F53BB0C4946E'])
              },
              {
                title: 'Delete',
                items: filterItems(['0AC9E6D0-B314-42DD-84D9-F6D02EFD4B9E'])
              },
              {
                title: 'Disclosure Updates',
                items: filterItems(['4611623E-CBD1-4440-B1AF-A3FAF6892FCA', '788A01BF-1C27-44D2-8DCE-797DB7FDAD94'])
              }
            ]
          },
          {
            title: 'Grid View',
            items: [
              {
                title: 'Grid',
                items: filterItems(['D3DC2DD5-D76C-49F8-A1B8-F9840E3ECE3D', '90022AC7-5583-43C4-9A58-1299F09015EB'])
              },
              {
                title: 'Help',
                items: filterItems(['DBB8F6E9-CC89-4EB9-AC17-00822D5AD28C'])
              }
            ]
          } //,
          // {
          //     title: 'Save As', items: [
          //         //Ribbon Groups
          //     ]
          // }
        );
        break;
      case 'FFF6F585-869B-4A47-A5FC-BC8C5DF47956': // Journals New
        ribbon.value.push(
          //Journal Entries Ribbon Tabs
          {
            title: 'Adjusting Journal Entries',
            items: [
              //Ribbon Groups
              {
                title: 'Tax Year',
                items: _.orderBy(filterItems(['82D2D6D0-B1B5-4134-8377-92A5D8809BA5']), ['title', 'index'], ['desc'])
              },
              {
                title: 'Dates',
                items: filterItems(['ECF9B9E7-F26B-4DA5-880D-FFFBC7E7525B'])
              },
              {
                title: 'Copy',
                items: filterItems(['E3DEE5F2-E8E7-421F-A291-F5EC6BE8F37D'])
              },
              {
                title: 'Move',
                items: filterItems(['85430B09-A983-4C33-A206-EA6C909C1BE7'])
              },
              {
                title: 'Import',
                items: filterItems(['21CC11DC-DB80-47C9-A0C8-0E862F4DEB0C'])
              },
              {
                title: 'Export',
                items: filterItems(['853479FC-BA56-4AC0-A4BD-B7F908BED8F9', 'A6B296D9-DC66-4EB4-960F-A53136F36811'])
              },
              {
                title: 'Delete',
                items: filterItems(['2F9EA6DB-DD49-4703-8A2C-B24F0C53629D'])
              },
              {
                title: 'Reports',
                items: filterItems(['A4D4CF09-520C-4C3F-8D6F-E52B657414F6'])
              },
              {
                title: 'Save',
                items: _.sortBy(filterItems(['33805D1D-25FD-40D1-811E-70246EBF74DF']))
              }
            ]
          },
          {
            title: 'Grid View',
            items: [
              {
                title: 'Grid',
                items: filterItems(['D3DC2DD5-D76C-49F8-A1B8-F9840E3ECE3D'])
              },
              {
                title: 'Help',
                items: filterItems(['DBB8F6E9-CC89-4EB9-AC17-00822D5AD28C'])
              }
            ]
          } //,
          // {
          //     title: 'Save As', items: [
          //         //Ribbon Groups
          //     ]
          // }
        );
        break;
      case '7765B360-4472-4921-919D-AF32CDE4EB91': //Cashbook Entries Tab
        ribbon.value.push(
          //Cashbook Entries Ribbon Tabs
          {
            title: 'Cashbook Entries',
            items: [
              //Ribbon Groups
              {
                title: 'Tax Year',
                items: _.orderBy(filterItems(['82D2D6D0-B1B5-4134-8377-92A5D8809BA5']), ['title', 'index'], ['desc'])
              },
              {
                title: 'Delete',
                items: filterItems(['BBD70D2B-3D5E-4241-AADA-242CA1593F51'])
              }
            ]
          },
          {
            title: 'Grid View',
            items: [
              {
                title: 'Grid',
                items: filterItems(['D3DC2DD5-D76C-49F8-A1B8-F9840E3ECE3D'])
              },
              {
                title: 'Help',
                items: filterItems(['DBB8F6E9-CC89-4EB9-AC17-00822D5AD28C'])
              }
            ]
          }
        );
        break;
      case '528A9405-33A4-4C37-8BCF-D1C7BE8B30A5-STEP2': //XBRL Step 2
        ribbon.value.push({
          title: 'Questionnaire',
          items: [
            {
              title: 'File',
              items: filterItems([
                '6E268E7D-102C-4636-A793-30C4E6D4F4E1',
                'D3DC2DD5-D76C-49F8-A1B8-F9840E3ECE3D',
                '3351E13E-6FC5-4FE2-97DA-EA62C77E28A8'
              ])
            }
          ]
        });
        break;
      case '528A9405-33A4-4C37-8BCF-D1C7BE8B30A5-STEP3': //XBRL Step 3
        ribbon.value.push({
          title: 'Tags',
          items: [
            {
              title: 'Periods',
              items: filterItems(['25E2F7CC-4B7F-496B-821B-D670C6E0B961', '4DF43AB1-4694-4AA7-B29A-39FDEA7443CF'])
            },
            {
              title: 'Tagging',
              items: filterItems(['A878ADE3-7E75-44B0-B7F3-1616254011F2'])
            },
            {
              title: 'Financials',
              items: filterItems(['D3E17FFE-5AB4-4AB7-8AA4-2254F4E2D544', '1BFC9361-ABC2-453D-B70E-65DBCB097D79'])
            },
            {
              title: 'Filters',
              items: filterItems(['B41FFDE5-9DB0-4592-BD5D-BA201E1B39FE', '691496AD-FE84-4FB1-95A9-B2CF7687A741'])
            },

            {
              title: 'Submission',
              items: filterItems(['1D811581-AB3D-415B-B034-830B29A9305B'])
            }
          ]
        });
        break;
      case '528A9405-33A4-4C37-8BCF-D1C7BE8B30A5-STEP4': //XBRL Step 4
        ribbon.value.push({
          title: 'Preview',
          items: [
            {
              title: 'Reload',
              items: filterItems(['BDAA815C-E50E-45DC-B691-955A5EF084ED'])
            }
          ]
        });
        break;
      // case 'B1E49E6C-49C4-406D-A0B3-099DFC69561D': //Signature Requests Tab
      //     ribbon.value.push(
      //         //Signature Requests Ribbon Tabs
      //         {
      //             title: 'Signature Requests', items: [
      //                 //Ribbon Groups
      //                 {
      //                     title: 'Documents', items:
      //                         filterItems([
      //                             'F8532408-E4F9-4034-8FC8-AEB848C2C817',
      //                             'D1D79F70-E914-4B51-B348-19CC6333C8C2',
      //                             '33D5F049-7A7B-4376-8335-4D98062AE512'

      //                         ])
      //                 }
      //             ]
      //         }
      //     )
      //     break;
      // case 3: //Generic Report Tab
      //     ribbon.value.push(
      //         //Generic Report Ribbon Tabs
      //         {
      //             title: 'Save As', items: [
      //                 //Ribbon Groups
      //             ]
      //         }
      //     )
      //     break;
      // case 4: //Generic Firesheet Tab
      //     ribbon.value.push(
      //         //Generic Firesheet Ribbon Tabs
      //         {
      //             title: 'Save As', items: [
      //                 //Ribbon Groups
      //             ]
      //         }
      //     )
      //     break;
    }

    return ribbon.value;
  };

  const filterItems = (ids: string[]) => {
    const result: RibbonItem[] = [];

    _.forEach(ids, (o) => {
      _.forEach(
        _.filter(ribbonItems.value, (i) => {
          return _.toLower(i.id) == _.toLower(o);
        }),
        (a) => {
          result.push(a);
        }
      );
    });
    return result;
  };

  return {
    ribbon,
    ribbonItems,
    buildRibbon,
    filterItems
  };
};
