import * as _ from 'lodash-es';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { TabData } from '@repo/core/models';
import { EventConstants } from '@repo/core/constants';
import { useDxEventBus } from '@repo/vue/utils';
import { useRootStore } from '@/stores';
import { useSessionStorage } from '@vueuse/core';
import { useMenuStore } from '@/stores';
import { useRibbonStore } from '@/stores';

export const useTabStore = defineStore('tab', () => {
  // utils
  const router = useRouter();
  const dxEventBus = useDxEventBus();

  // stores
  const rootStore = useRootStore();
  const menuStore = useMenuStore();
  const ribbonStore = useRibbonStore();

  // state
  const tabs = useSessionStorage<TabData[]>('tabs', []);

  // getters
  const tabComponents = computed(() =>
    _.map(
      _.filter(router.getRoutes(), (r) =>
        _.some(tabs.value, (t) => _.toLower(r.name?.toString()) === _.toLower(t.$name))
      ),
      (r) => r.meta.component?.toString() ?? ''
    )
  );
  const activeTab = computed(() => _.find(tabs.value, (t) => _.toLower(t.id) === _.toLower(rootStore.tabId)));
  const activeTabIndex = computed(() => _.indexOf(tabs.value, activeTab.value));

  // actions
  const addTab = (tab: TabData) => {
    const currentTab = _.find(tabs.value, (t) => _.toLower(t.id) === _.toLower(tab.id));
    if (!currentTab) {
      tabs.value.push({ ...tab });
    }
    ribbonStore.loadRibbon(tab.id);
    ribbonStore.applySelectedRibbonItems(tab.id);

    console.info('Tab added: ' + tab.$name + ' - ' + tab.id);
  };

  const addTabById = (tabId: string | undefined) => {
    if (!tabId) return;
    const tab = _.find(tabs.value, (t) => {
      return _.toUpper(t.id) == _.toUpper(tabId);
    });
    if (!tab) {
      const tab = findTabById(tabId);
      if (tab) {
        addTab(tab);
      }
    }
  };

  const findTabById = (tabId: string) => {
    const menuItem = menuStore.findMenuItem(tabId);
    if (menuItem) {
      const tab: TabData = {
        id: menuItem.id,
        $name: menuItem.$name,
        $component: menuItem.$component,
        $options: menuItem.$options,
        options: menuItem.options,
        title: menuItem.title,
        url: menuItem.url,
        color: menuItem.color,
        fileType: menuItem.fileType,
        allowMultiple: menuItem.allowMultiple,
        disabled: menuItem.disabled
      };
      return tab;
    }
  };

  const removeTab = (tabId: string | undefined) => {
    const tab = _.find(tabs.value, (t) => _.toLower(t.id) === _.toLower(tabId));
    if (tab) {
      tabs.value = _.filter(tabs.value, (t) => _.toLower(t.id) !== _.toLower(tabId));

      if (tabs.value.length === 0) {
        resetTabState();
      }

      dxEventBus.emit(EventConstants.closeTab + tab.id);
      console.info('Tab closed: ' + tab.$name + ' - ' + tab.id);
    }
  };

  const resetTabState = () => {
    tabs.value = [];
    dxEventBus.emit(EventConstants.closeTabs);
    console.info('Tabs cleared');
  };

  const state = { tabs };
  const getters = { tabComponents, activeTab, activeTabIndex };
  const actions = { addTab, addTabById, findTabById, removeTab, resetTabState };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTabStore, import.meta.hot));
}
