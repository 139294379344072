import { shallowRef } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { MenuData } from '@repo/core/models';
import { useDxClientMenu } from '@/utils';

export const useMenuStore = defineStore('menu', () => {
  // utils
  const dxMenu = useDxClientMenu();

  // state
  const menus = shallowRef<MenuData[]>([]);

  // actions
  const fetchMenuItems = async () => {
    const result = await dxMenu.loadMenuItems();
    menus.value = result;
    return result;
  };

  const findMenuItem = (id: string | undefined) => {
    return dxMenu.findMenuItem(menus.value, id);
  };

  const state = { menus };
  const actions = { fetchMenuItems, findMenuItem };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot));
}
