import { DateTimeSchema, MessageSchema, NumberSchema } from '@repo/vue/locales';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export const useDxToast = () => {
  const toast = useToast();

  // locale
  const { t, d, n } = useI18n<[MessageSchema, DateTimeSchema, NumberSchema]>();

  // actions
  const success = (detail: string, summary: string = t('message.success'), life = 3000) => {
    toast.add({
      summary: summary,
      detail: detail,
      severity: 'success',
      life: life
    });
  };

  const info = (detail: string, summary: string = t('message.info'), life = 3000) => {
    toast.add({
      summary: summary,
      detail: detail,
      severity: 'info',
      life: life
    });
  };

  const warn = (detail: string, summary: string = t('message.warning'), life = 3000) => {
    toast.add({
      summary: summary,
      detail: detail,
      severity: 'warn',
      life: life
    });
  };

  const error = (detail: string, summary: string = t('message.error'), life = 3000) => {
    toast.add({
      summary: summary,
      detail: detail,
      severity: 'error',
      life: life
    });
  };

  return {
    success,
    info,
    warn,
    error
  };
};
