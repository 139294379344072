import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useClientService } from '@repo/core/services';
import {
  Client,
  ClientParameterOfGuid,
  ClientParameterOfNullableGuid,
  ClientParameterOfString
} from '@repo/core/models';
import { useRootStore } from '@/stores';
import { useTabStore } from '@/stores';
import { useFinancialStore } from '@/stores';

export const useClientStore = defineStore('client', () => {
  // services
  const clientService = useClientService();

  // stores
  const rootStore = useRootStore();
  const tabStore = useTabStore();
  const financialStore = useFinancialStore();

  // state
  const currentClient = ref<Client>(<Client>{});

  // getters
  const isReadOnly = computed(() => {
    let readonly = false;
    if (currentClient.value.readOnly || currentClient.value.uploadLocked) {
      readonly = true;
    }
    if (currentClient.value.bookedIn && !rootStore.supportAccessRequestId) {
      readonly = true;
    }
    return readonly;
  });

  // actions
  const fetchClients = () => {
    return clientService.get('?$filter=deleted eq false&$filter=archived eq false&$expand=*');
  };
  const fetchClientsByFilter = (parameters?: string) => {
    return clientService.get(parameters);
  };
  const fetchCurrentClient = async () => {
    const result = await clientService.getCurrent();
    currentClient.value = result;
    return result;
  };
  const fetchClientAccess = (id: string) => {
    return clientService.getAccess(id);
  };
  const saveClientAccess = async (clientId: string, userIds: string[]) => {
    const result = await clientService.saveClientAccess(clientId, userIds);
    await fetchClientAccess(clientId);
    return result;
  };
  const saveClients = async (data: Client[], options?: any) => {
    const result = await clientService.post(data, options);
    await fetchClients();
    return result;
  };
  const copyClients = (data: ClientParameterOfString[]) => {
    return clientService.copy(data);
  };
  const archiveClients = (ids: string[]) => {
    return clientService.archive(ids);
  };
  const unArchiveClients = (ids: string[]) => {
    return clientService.unarchive(ids);
  };
  const deleteClients = (ids: string[]) => {
    return clientService.deleteClients(ids);
  };
  const restoreClients = (ids: string[]) => {
    return clientService.restore(ids);
  };
  const lockClients = (ids: string[]) => {
    return clientService.lock(ids);
  };
  const lockClientsAsReadOnly = (ids: string[]) => {
    return clientService.lockAsReadOnly(ids);
  };
  const unlockClients = (ids: string[]) => {
    return clientService.unlock(ids);
  };
  const unlockClientsAsReadOnly = (ids: string[]) => {
    return clientService.unlockAsReadOnly(ids);
  };
  const resyncPriorClient = (clientId?: string) => {
    return clientService.resyncPrior(clientId);
  };
  const switchFramework = (data: ClientParameterOfGuid[]) => {
    return clientService.switchFramework(data);
  };
  const switchMethodology = (data: ClientParameterOfNullableGuid[]) => {
    return clientService.switchMethodology(data);
  };
  const unlockDemo = async (data: ClientParameterOfString[]) => {
    const result = await clientService.unlockDemo(data);
    if (currentClient.value?.id) {
      currentClient.value = await fetchCurrentClient();
    }
    return result;
  };
  const openClient = (clientId: string | undefined) => {
    rootStore.$patch({
      clientId: clientId
    });
  };
  const resetClientClaims = () => {
    tabStore.resetTabState();
    financialStore.resetFinancialState();
    rootStore.clearCurrentClient();
  };

  const state = {
    currentClient
  };
  const getters = {
    isReadOnly: isReadOnly
  };
  const actions = {
    fetchClients,
    fetchClientsByFilter,
    fetchCurrentClient,
    fetchClientAccess,
    saveClients,
    copyClients,
    saveClientAccess,
    archiveClients,
    unArchiveClients,
    deleteClients,
    restoreClients,
    lockClients,
    lockClientsAsReadOnly,
    unlockClients,
    unlockClientsAsReadOnly,
    resyncPriorClient,
    switchFramework,
    switchMethodology,
    unlockDemo,
    resetClientClaims,
    openClient
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot));
}
