import * as _ from 'lodash-es';

export const DataTableDirective = {
  mounted: (el: any) => {
    el.addEventListener('keydown', onKeydown, true);
  },
  unmounted: (el: any) => {
    el.removeEventListener('keydown', onKeydown);
  }
  // updated(el: any) {
  //   el.querySelectorAll('.p-column-filter-menu-button, .p-checkbox-box, .p-selectable-row').forEach(
  //     (item: HTMLElement) => {
  //       item.setAttribute('tabindex', '-1');
  //     }
  //   );
  // }
};

let editing = false;

const onKeydown = (event: KeyboardEvent) => {
  // console.log('event', event);

  const el = event.target as HTMLElement;
  const table = el.closest('table.p-datatable-table') as HTMLTableElement;
  const row = el.closest('tr') as HTMLTableRowElement;
  const cell = el.closest('td, th') as HTMLTableCellElement;
  const input = el.closest('input.p-inputtext') as HTMLInputElement;

  if (!table || !row || !cell || !input) return;

  let rowIndex = row.rowIndex;
  let cellIndex = cell.cellIndex - 1;

  switch (event.key) {
    case 'ArrowUp':
      if (editing) break;
      rowIndex--;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'ArrowDown':
      if (editing) break;
      rowIndex++;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'ArrowLeft':
      if (editing) break;
      cellIndex--;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'ArrowRight':
      if (editing) break;
      cellIndex++;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'Backspace':
      if (editing) break;
      editing = true;
      input.readOnly = false;
      event.stopPropagation();
      break;
    case 'Enter':
      editing = false;
      input.readOnly = true;
      rowIndex++;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'Escape':
      editing = false;
      input.readOnly = true;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'F2':
      editing = true;
      input.readOnly = false;
      event.preventDefault();
      event.stopPropagation();
      break;
    case 'Tab':
      editing = false;
      input.readOnly = true;
      if (event.shiftKey) {
        cellIndex--;
      } else {
        cellIndex++;
      }
      event.preventDefault();
      event.stopPropagation();
      break;
    default:
      if (_.startsWith(event.code, 'Digit') || _.startsWith(event.code, 'Key')) {
        editing = true;
        input.readOnly = false;
      }
      return;
  }

  // move to previous cell when at the start of a row
  if (cellIndex < 0) {
    cellIndex = 1;
    rowIndex--;
  }

  // move to next cell when at the end of a row
  if (cellIndex >= row.cells.length - 1) {
    cellIndex = 0;
    rowIndex++;
  }

  // eslint-disable-next-line lodash/prefer-lodash-method
  // const nextCell = $(table)
  //   .find('tr')
  //   .eq(rowIndex)
  //   .find('td.p-editable-column, th.p-filter-column input.p-inputtext')
  //   .eq(cellIndex);

  // console.log(nextCell[0]);

  // nextCell?.trigger('click');
  // nextCell?.trigger('focus');
};
