import { api } from '@repo/core/config';
import {
  Client,
  ClientParameterOfGuid,
  ClientParameterOfNullableGuid,
  ClientParameterOfString,
  UserClient
} from '@repo/core/models';

export const useClientService = () => {
  const get = async (parameters?: string): Promise<Client[]> => {
    parameters = parameters || '';
    try {
      const response = await api.get('clients' + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAccess = async (id: string): Promise<UserClient[]> => {
    try {
      const response = await api.get(`clients/getClientAccess/${id}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveClientAccess = async (clientId: string, userIds: string[]) => {
    try {
      const response = await api.post(`clients/saveClientAccess/${clientId}`, userIds);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCurrent = async (): Promise<Client> => {
    try {
      const response = await api.get(`clients/getCurrent?$expand=*`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: Client[], options: any) => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    try {
      const response = await api.post(`clients`, data, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const copy = async (data: ClientParameterOfString[]) => {
    try {
      const response = await api.post(`clients/copy`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const lock = async (data: string[]) => {
    try {
      const response = await api.post(`clients/lock`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const lockAsReadOnly = async (data: string[]) => {
    try {
      const response = await api.post(`clients/lockAsReadOnly`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unlock = async (data: string[]) => {
    try {
      const response = await api.post(`clients/unlock`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unlockAsReadOnly = async (data: string[]) => {
    try {
      const response = await api.post(`clients/unlockAsReadOnly`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const resyncPrior = async (fromClientId?: string) => {
    try {
      const response = await api.post(`clients/resyncPrior`, '"' + fromClientId + '"' || '');
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const restore = async (keys: string[]) => {
    try {
      const response = await api.post(`clients/restore`, keys);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteClients = async (keys: string[]) => {
    try {
      const response = await api.delete(`clients/keys`, { data: '"' + keys + '"' });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const switchFramework = async (data: ClientParameterOfGuid[]) => {
    try {
      const response = await api.post(`clients/switchFrameworks`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const switchMethodology = async (data: ClientParameterOfNullableGuid[]) => {
    try {
      const response = await api.post(`clients/switchMethodologies`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const archive = async (data: string[]) => {
    try {
      const response = await api.post(`clients/archive`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unarchive = async (data: string[]) => {
    try {
      const response = await api.post(`clients/unarchive`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unlockDemo = async (data: ClientParameterOfGuid[]) => {
    try {
      const response = await api.post(`clients/unlockDemo`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getAccess,
    saveClientAccess,
    getCurrent,
    post,
    copy,
    lock,
    lockAsReadOnly,
    unlock,
    unlockAsReadOnly,
    resyncPrior,
    restore,
    deleteClients,
    switchFramework,
    switchMethodology,
    archive,
    unarchive,
    unlockDemo
  };
};
