import { computed } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { AppConstants } from '@repo/core/constants';

export const useAppStore = defineStore('app', () => {
  // state
  const isDark = useLocalStorage<boolean>('isDark', AppConstants.defaultDark);
  const scale = useLocalStorage<number>('scale', AppConstants.defaultScale);
  const locale = useLocalStorage<string>('locale', AppConstants.defaultLocale);
  const inputStyle = useLocalStorage<string>('inputStyle', AppConstants.defaultInputStyle);
  const selectedTheme = useLocalStorage<string>('selectedTheme', AppConstants.defaultTheme);

  // getters
  const isIFrame = computed(() => window.self !== window.top);

  // actions
  const setIsDark = (dark: boolean) => {
    isDark.value = dark;
  };
  const setSelectedTheme = (dark: boolean) => {
    selectedTheme.value = dark ? 'Dark' : 'Light';
  };
  const setScale = (newScale: number) => {
    scale.value = newScale;
  };
  const setLocale = (newLocale: string) => {
    locale.value = newLocale;
  };
  const setInputStyle = (newInputStyle: string) => {
    inputStyle.value = newInputStyle;
  };

  const state = { isDark, selectedTheme, scale, locale, inputStyle };
  const getters = { isIFrame };
  const actions = { setIsDark, setSelectedTheme, setScale, setLocale, setInputStyle };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
