import * as _ from 'lodash-es';
import { api } from '@repo/core/config';
import { FinancialData } from '@repo/core/models';

export const useFinancialDataService = () => {
  const get = async (parameters?: string, options?: any): Promise<FinancialData[]> => {
    if (!options) options = {};
    parameters = parameters || '';
    try {
      const response = await api.get('financialData' + parameters, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: FinancialData[], options: any) => {
    try {
      if (!options) options = {};
      options.autoSave = options.autoSave != null ? options.autoSave : true;

      if (data && data.length > 0) {
        const updated = _.filter(data, { $dirty: true });
        if (JSON.stringify(updated).match(/<[^>]+>/i)) {
          _.forEach(data, (entry: any) => {
            if (_.includes(JSON.stringify(entry), '<')) {
              entry.$order = 1;
            } else if (_.includes(JSON.stringify(entry), '>')) {
              entry.$order = -1;
            } else {
              entry.$order = 0;
            }
          });
        }
        data = _.sortBy(data, ['$order']);
      }

      const response = await api.post(
        `financialData`,
        _.map(data, (entry) => {
          if (entry.accountMapping) {
            delete entry.accountMapping.created;
            delete entry.accountMapping.modified;
          }
          return _.omit(entry, ['prior', '$prior', 'created', 'modified']);
        }),
        {
          headers: options
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const exportToExcel = async (id: string, columns: any): Promise<Blob> => {
    try {
      const response = await api.post('financialData/ExportToExcel/' + id, JSON.stringify(columns), {
        responseType: 'blob'
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    post,
    exportToExcel
  };
};
