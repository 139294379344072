import { FireSheetOptions } from '@repo/core/models';
import { api } from '@repo/core/config';
import { MaybeRef } from 'vue';

export const useDxFireSheet = (sheet?: MaybeRef) => {
  let fsx: any;

  const loadFiresheetJs = () => {
    const url = new URL('/assets/libs/firesheet/dx-firesheet.js', import.meta.url).href;
    try {
      const scriptEl = document.createElement('script');
      scriptEl.id = 'firesheet-js';
      scriptEl.src = url;
      scriptEl.type = 'text/javascript';
      scriptEl.async = false;
      document.head.appendChild(scriptEl);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const loadFiresheet = async (options: FireSheetOptions) => {
    const [i18n, langList, tb] = await Promise.all([fetchI18n(), fetchLanguages(), fetchToolbar()]);

    const element = document.querySelector(options.selector) as HTMLElement;
    if (!window.FSX_I18N) window.FSX_I18N = i18n;
    fsx = new window.DXFSX.Instance(element, {
      acePath: '/assets/libs/firesheet/ace',
      tabs: options.tabs ?? true,
      toolbar: options.toolbar ?? true,
      fxbar: options.fxbar ?? true,
      i18path: '/assets/libs/firesheet/langs',
      languages: langList.langs
    });

    fsx.client = { name: 'Draftworx' };

    fsx.setRibbonData(tb);
    fsx.enableJoeMode();
    // fsx.enableChristoMode();

    if (sheet) {
      sheet.value = fsx;
    }

    if (options.testMode) {
      loadFile();
    }
  };

  const destroyFiresheet = () => {
    fsx.destroy();
    // document.head.querySelector('#firesheet-js')?.remove();
    // document.head.querySelector('#firesheet-css')?.remove();
  };

  const loadFile = () => {
    // const file = 'BlankBook.xlsx';
    // const file = 'IFRS+ Financials Big Notes.xlsx';
    const file = 'IFRS SME+ Financials LOAD ERR.xlsx';
    fsx.started = Date.now();
    const req = new XMLHttpRequest();
    req.responseType = 'blob';
    req.open('GET', `/assets/docs/${file}`, true);
    req.onload = () => {
      if (req.status >= 200 && req.status < 400) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(req.response);
        reader.onload = (e) => {
          console.log(`Received ... passing to FSX | ${file} |`);
          if (e.target) fsx.open(e.target.result);
        };
      } else {
        alert('Error getting file.');
      }
    };
    req.send();
  };

  const fetchI18n = async () => {
    const url = new URL('/assets/libs/firesheet/langs/en-UK/i18n.json', import.meta.url).href;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchLanguages = async () => {
    const url = new URL('/assets/libs/firesheet/langs/languages.json', import.meta.url).href;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchToolbar = async () => {
    const url = new URL('/assets/libs/firesheet/langs/en-UK/toolbar.json', import.meta.url).href;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fsRefreshDisplay = () => {
    console.log('fsRefreshDisplay');

    // fsx.refreshDisplay();
    // window.DXFSX.refreshDisplay();
    // eslint-disable-next-line no-debugger
    // debugger;
  };

  return {
    fsx,
    loadFiresheet,
    loadFiresheetJs,
    destroyFiresheet,
    fsRefreshDisplay
  };
};
