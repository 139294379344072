import { api } from '@repo/core/config';
import { DirectImportConnection } from '@repo/core/models';

export const useDirectImportConnectionService = () => {
  const get = async (parameters?: string) => {
    try {
      parameters = parameters || '';
      const response = await api.get('directImportConnection' + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  const getClientConnection = async () => {
    try {
      const response = await api.get(`directImportConnection/getClientConnection`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: DirectImportConnection[]) => {
    try {
      const response = await api.post(`directImportConnection`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteConnections = async (data: string[]) => {
    try {
      const response = await api.delete(`directImportConnection/keys`, { data: '"' + data + '"' });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getClientConnection,
    post,
    deleteConnections
  };
};
