import * as _ from 'lodash-es';
import { MenuData } from '@repo/core/models';
import { useMenuService } from '@repo/core/services';

export const useDxClientMenu = () => {
  // services
  const menuService = useMenuService();

  // methods
  const loadMenuItems = async (): Promise<MenuData[]> => {
    try {
      const result = await menuService.get();

      // TODO: Implement database columns
      _.forEach(result, (a: MenuData) => {
        a.$name = getRouteName(a);

        _.forEach(a.items, (b: MenuData) => {
          b.$name = getRouteName(b);

          _.forEach(b.items, (c: MenuData) => {
            c.$name = getRouteName(c);
          });
        });
      });

      // TODO: Remove XBRL Questionnaire
      const xbrl = _.find(result, (r: any) => {
        return _.toLower(r.id) === 'e7ac5859-c91e-425d-9962-61367dddeaeb';
      });
      if (xbrl) {
        xbrl.items = _.filter(xbrl.items, (i: any) => {
          return !(i.fileType === 1 && i.title === 'CIPC XBRL Questionnaire');
        });
      }

      // Add custom menu items
      const clientSetupMenu = findMenuItem(result, '015F5A31-9C6F-40A6-91C9-6825C0AB2BDD');
      if (clientSetupMenu && clientSetupMenu.items) {
        const menuItems = clientSetupMenu.items as MenuData[];
        menuItems.push(
          {
            id: 'f5b0d320-ff2b-447f-8d15-bcb160865e47',
            $name: 'dashboard',
            $component: 'DashboardView',
            title: 'Dashboard',
            icon: 'fa fa-dashboard',
            menuType: 1
          },
          {
            id: '05c549a8-aac1-40fd-bf0b-ee29eb25449e',
            $name: 'demos',
            $component: 'DemosView',
            title: 'Demos',
            icon: 'fa fa-desktop',
            menuType: 1
          }
        );
      }

      return result;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const findMenuItem = (items: MenuData[] | undefined, id: string | undefined): MenuData | undefined => {
    if (!items || !id) return;
    for (const item of items) {
      if (_.toLower(item.id) == _.toLower(id)) return item;
      const child = findMenuItem(item.items, id);
      if (child) {
        return child;
      }
    }
  };

  const getRouteName = (item: MenuData) => {
    if (item.url) {
      const fromIndex = item.url.lastIndexOf('/');
      const toIndex = item.url.lastIndexOf('.html');

      if (fromIndex && toIndex) {
        const fragment = item.url.substring(fromIndex + 1, toIndex);
        if (fragment) {
          // console.log('url', fragment);
          return fragment;
        }
      }
    } else if (item.options) {
      const options = JSON.parse(item.options);
      const fromIndex = options.url.lastIndexOf('/');
      const toIndex = options.url.lastIndexOf('.html');

      if (fromIndex && toIndex) {
        const fragment = options.url.substring(fromIndex + 1, toIndex);
        if (fragment) {
          // console.log('options', fragment);
          return fragment;
        }
      }
    }
    return null;
  };

  return {
    loadMenuItems,
    findMenuItem
  };
};
