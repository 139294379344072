<script setup lang="ts">
// utils
import { onBeforeUnmount } from 'vue';
import { useDxProgress } from '@repo/vue/utils';

// utils
const { isLoadingSpinner, clearLoader } = useDxProgress();

// hooks
onBeforeUnmount(() => {
  clearLoader();
});
</script>

<template>
  <div v-if="isLoadingSpinner" class="loader">
    <div class="loader-content">
      <div class="loader-spinner"></div>
      <div class="loader-text">Loading...</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loader {
  position: fixed;
  top: 30%;
  left: 45%;
  width: 135px;
  z-index: 10000001;

  .loader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;

    .loader-spinner {
      width: 40px;
      height: 40px;
      background-color: var(--primary-color);

      margin: 100px auto;
      animation: sk-rotateplane 1.2s infinite ease-in-out;
    }

    .loader-text {
      font-size: 20px;
      color: var(--primary-color);
    }
  }
}

@-webkit-keyframes sk-rotateplane {
  0% {
    transform: perspective(120px);
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
</style>
