import { api } from '@repo/core/config';
import { MenuData } from '@repo/core/models';

export const useMenuService = () => {
  const get = async (id?: string): Promise<MenuData[]> => {
    const url = 'menuItems/' + (id || '');
    try {
      const response = await api.get(url, { headers: { autoSave: true } });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get
  };
};
