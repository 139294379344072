import { SetupPlugin } from './plugin';

/**
 * Creates the Setup plugin.
 *
 * @returns An instance of SetupPlugin
 */
export const createSetup = () => {
  return new SetupPlugin();
};
