import { App } from 'vue';

import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import BadgeDirective from 'primevue/badgedirective';
import StyleClass from 'primevue/styleclass';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DynamicDialog from 'primevue/dynamicdialog';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import TabMenu from 'primevue/tabmenu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

export class PrimeVuePlugin {
  install(app: App) {
    // locale
    const t = app.config.globalProperties.$t;

    app.use(PrimeVue, {
      ripple: false,
      pt: {
        dialog: {
          closeButton: {
            title: t('message.cancel')
          }
        }
      }
    });
    app.use(DialogService);
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive('tooltip', Tooltip);
    app.directive('ripple', Ripple);
    app.directive('badge', BadgeDirective);
    app.directive('styleclass', StyleClass);

    // app.component('Accordion', Accordion);
    // app.component('AccordionTab', AccordionTab);
    app.component('AutoComplete', AutoComplete);
    // app.component('Avatar', Avatar);
    // app.component('AvatarGroup', AvatarGroup);
    app.component('Badge', Badge);
    // app.component('Breadcrumb', Breadcrumb);
    app.component('BlockUI', BlockUI);
    app.component('Button', Button);
    app.component('Calendar', Calendar);
    app.component('Card', Card);
    // app.component('Carousel', Carousel);
    app.component('Chart', Chart);
    app.component('Checkbox', Checkbox);
    // app.component('Chip', Chip);
    // app.component('Chips', Chips);
    // app.component('ColorPicker', ColorPicker);
    app.component('Column', Column);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('ConfirmPopup', ConfirmPopup);
    app.component('DynamicDialog', DynamicDialog);
    // app.component('ContextMenu', ContextMenu);
    app.component('DataTable', DataTable);
    app.component('DataView', DataView);
    app.component('DataViewLayoutOptions', DataViewLayoutOptions);
    app.component('Dialog', Dialog);
    // app.component('Divider', Divider);
    app.component('Dropdown', Dropdown);
    app.component('Editor', Editor);
    // app.component('Fieldset', Fieldset);
    // app.component('FileUpload', FileUpload);
    // app.component('FullCalendar', FullCalendar);
    // app.component('Galleria', Galleria);
    // app.component('Image', Image);
    // app.component('InlineMessage', InlineMessage);
    // app.component('Inplace', Inplace);
    app.component('InputMask', InputMask);
    app.component('InputNumber', InputNumber);
    app.component('InputSwitch', InputSwitch);
    app.component('InputText', InputText);
    app.component('Knob', Knob);
    app.component('Listbox', Listbox);
    // app.component('MegaMenu', MegaMenu);
    app.component('Menu', Menu);
    app.component('Menubar', Menubar);
    // app.component('Message', Message);
    // app.component('MultiSelect', MultiSelect);
    // app.component('OrderList', OrderList);
    // app.component('OrganizationChart', OrganizationChart);
    // app.component('OverlayPanel', OverlayPanel);
    // app.component('Paginator', Paginator);
    app.component('Panel', Panel);
    // app.component('PanelMenu', PanelMenu);
    // app.component('Password', Password);
    // app.component('PickList', PickList);
    app.component('ProgressBar', ProgressBar);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('RadioButton', RadioButton);
    app.component('Rating', Rating);
    app.component('SelectButton', SelectButton);
    // app.component('ScrollPanel', ScrollPanel);
    // app.component('ScrollTop', ScrollTop);
    // app.component('Slider', Slider);
    app.component('Sidebar', Sidebar);
    // app.component('Skeleton', Skeleton);
    // app.component('SpeedDial', SpeedDial);
    app.component('SplitButton', SplitButton);
    app.component('Splitter', Splitter);
    app.component('SplitterPanel', SplitterPanel);
    // app.component('Steps', Steps);
    app.component('TabMenu', TabMenu);
    app.component('TabView', TabView);
    app.component('TabPanel', TabPanel);
    // app.component('Tag', Tag);
    app.component('Textarea', Textarea);
    // app.component('TieredMenu', TieredMenu);
    // app.component('Timeline', Timeline);
    app.component('Toast', Toast);
    app.component('Toolbar', Toolbar);
    app.component('InputGroup', InputGroup);
    app.component('InputGroupAddon', InputGroupAddon);
    // app.component('ToggleButton', ToggleButton);
    // app.component('Tree', Tree);
    // app.component('TreeSelect', TreeSelect);
    // app.component('TreeTable', TreeTable);
    // app.component('TriStateCheckbox', TriStateCheckbox);
  }
}
