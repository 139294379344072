import { acceptHMRUpdate, defineStore } from 'pinia';
import { EventData } from '@repo/core/models';
import { ref } from 'vue';

export const useEventStore = defineStore('event', () => {
  // state
  const eventData = ref<EventData>({} as EventData);

  // actions
  const setEventData = (data: EventData) => {
    eventData.value = { ...data };
  };

  const state = { eventData };
  const actions = { setEventData };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventStore, import.meta.hot));
}
