import moment from 'moment';
import { api } from '@repo/core/config';
import { Practice } from '@repo/core/models';

export const usePracticeService = () => {
  const get = async (parameters?: string): Promise<Practice[]> => {
    parameters = parameters || '';
    try {
      const response = await api.get('practices' + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getById = async (id: string): Promise<Practice> => {
    try {
      const response = await api.get('practices' + (id !== undefined ? '/' + id : ''));
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCurrent = async (): Promise<Practice> => {
    try {
      const response = await api.get('practices/getCurrent');
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: Practice[], options: any): Promise<Practice[]> => {
    try {
      if (!options) options = {};
      options.autoSave = options.autoSave !== null ? options.autoSave : true;
      const response = await api.post('practices', data, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getPracticeStatementPdf = async (fromDate: Date | undefined, toDate: Date | undefined): Promise<Blob> => {
    try {
      const response = await api.get(
        'practiceStatementEntries/pdf?fromDate=' +
          moment(fromDate).format('YYYY-MM-DD') +
          '&toDate=' +
          moment(toDate).format('YYYY-MM-DD'),
        { responseType: 'blob' }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getPracticeStatementExcel = async (fromDate: Date | undefined, toDate: Date | undefined): Promise<Blob> => {
    try {
      const response = await api.get(
        'practiceStatementEntries/excel?fromDate=' +
          moment(fromDate).format('YYYY-MM-DD') +
          '&toDate=' +
          moment(toDate).format('YYYY-MM-DD'),
        { responseType: 'blob' }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getById,
    getCurrent,
    post,
    getPracticeStatementPdf,
    getPracticeStatementExcel
  };
};
