<script setup lang="ts">
import { computed, onBeforeUnmount } from 'vue';
import { useDxProgress } from '@repo/vue/utils';

// props
defineProps({
  throttle: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 2
  },
  color: {
    type: [String],
    default: `repeating-linear-gradient(to right,var(--primary-color) 0%,var(--primary-300) 50%,var(--primary-600) 100%)`
  }
});

// utils
const { isLoadingBar, progressBar, clearLoader } = useDxProgress();

// computed
const isIFrame = computed(() => window.self !== window.top);

// hooks
onBeforeUnmount(() => {
  clearLoader();
});
</script>

<template>
  <div
    id="loading-bar"
    :style="{
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      pointerEvents: 'none',
      width: 'auto',
      height: `${height}px`,
      opacity: isLoadingBar ? 1 : 0,
      background: color || undefined,
      backgroundSize: `${(100 / progressBar) * 100}% auto`,
      transform: `scaleX(${progressBar}%)`,
      transformOrigin: 'left',
      transition: 'transform 0.1s, height 0.4s, opacity 0.4s',
      zIndex: 999999
    }"
  >
    <div class="bar"><div class="peg"></div></div>
  </div>
  <div v-if="!isIFrame" id="loading-bar-spinner" :style="{ opacity: isLoadingBar ? 1 : 0 }">
    <div class="spinner-icon"></div>
  </div>
</template>

<style scoped lang="scss">
/* Make clicks pass-through */
#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  transition: 350ms linear all;
}

#loading-bar .bar {
  transition: width 350ms;
  background: var(--primary-color);
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

/* Fancy blur effect */
#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  opacity: 0.45;
  box-shadow: var(--primary-color) 1px 0 6px 1px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 10002;
  top: 12px;
  left: 19px;
}

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;

  border: solid 2px transparent;
  border-top-color: var(--primary-color);
  border-left-color: var(--primary-color);
  border-radius: 50%;

  animation: loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-ms-keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
