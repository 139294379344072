import * as _ from 'lodash-es';
import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { DirectImportConnection, JournalEntry } from '@repo/core/models';
import { useDirectImportConnectionService, useDirectImportService } from '@repo/core/services';
import { useDxConfirm } from '@repo/vue/utils';
import { useClientStore } from '@/stores';

export const useDirectImportStore = defineStore('directImport', () => {
  // services
  const directImportService = useDirectImportService();
  const directImportConnectionService = useDirectImportConnectionService();

  // utils
  const dxConfirm = useDxConfirm();

  // stores
  const clientStore = useClientStore();

  // state
  const companies = ref<any[]>([]);
  const sessionStatus = ref<any>();
  const directImportConnection = ref<DirectImportConnection>();
  const integrations = ref<any[]>([]);

  // getters
  const connected = computed(() => !_.isNil(directImportConnection.value?.companyId));
  const connectedIntegration = computed(() => {
    return _.find(integrations.value, (x) => {
      return !directImportConnection.value ? false : x.id === directImportConnection.value?.integrationId;
    });
  });
  const xeroConnectionBeta = computed(() => !!clientStore.currentClient?.directImportBeta);

  // actions
  const canConnect = (integrationId: string | undefined) => {
    return _.toUpper(integrationId) == 'E48824AD-1CB4-4192-A317-F6BB15850BF0' ? xeroConnectionBeta.value : true;
  };

  const canDrillDown = () => {
    return (
      connected.value &&
      canConnect(directImportConnection.value?.directImportConnectionId) &&
      _.toUpper(directImportConnection.value?.integrationId) !== 'E48824AD-1CB4-4192-A317-F6BB15850BF0' &&
      _.toUpper(directImportConnection.value?.integrationId) !== '141ED04F-7B27-41D6-80E8-F1C54DF295AB'
    );
  };

  const fetchIntegrations = async () => {
    const result = await directImportService.getIntegrations();
    integrations.value = result;
    return result;
  };

  const fetchClientConnection = async () => {
    const result = await directImportConnectionService.getClientConnection();
    directImportConnection.value = result;
    return result;
  };

  const disconnect = async (companyId?: string | undefined) => {
    await directImportConnectionService.deleteConnections([directImportConnection.value?.id ?? '']);
    await directImportService.disconnect(directImportConnection.value?.directImportConnectionId, companyId);
    directImportConnection.value = {} as DirectImportConnection;
  };

  const openDirectImports = async (integration: any) => {
    if (connected.value && connectedIntegration.value?.id && !canConnect(integration.id)) {
      const result = await dxConfirm.confirmation(
        '',
        `This client is connected to <strong>${connectedIntegration.value?.name}</strong>. Do you want to disconnect from <strong>${connectedIntegration.value?.name}</strong>?`,
        'Yes',
        'Cancel'
      );
      if (result) {
        await disconnect();
        return true;
      } else {
        return false;
      }
    } else if (!canConnect(integration?.id)) {
      return true;
    }

    if (
      connectedIntegration.value &&
      directImportConnection.value &&
      directImportConnection.value?.integrationId !== integration.id
    ) {
      const result = await dxConfirm.confirmation(
        '',
        `This client is already connected to <strong>${connectedIntegration.value?.name}</strong>. Do you want to disconnect from <strong>${connectedIntegration.value?.name}</strong> and connect to <strong>${integration?.name}</strong> instead?`,
        'Yes',
        'Cancel'
      );
      if (result) {
        await disconnect();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const validateConnection = async () => {
    try {
      return directImportService.validate(
        directImportConnection.value?.directImportConnectionId,
        directImportConnection.value?.companyId
      );
    } catch (error: any) {
      console.error(error);
      await disconnect();
    }
  };

  const createConnection = async (connection: DirectImportConnection) => {
    try {
      await directImportConnectionService.post([connection]);
      directImportConnection.value = connection;

      if (!directImportConnection.value?.expiry) {
        delete directImportConnection.value?.expiry;
      }

      return connection;
    } catch (err: any) {
      console.error(err);
      await disconnect();
    }
  };

  const login = (
    integrationId: string | undefined,
    country: string | undefined,
    identifier: string | undefined,
    fields: any
  ) => {
    const persist =
      _.toUpper(integrationId) == 'E48824AD-1CB4-4192-A317-F6BB15850BF0' ? xeroConnectionBeta.value : true;
    return directImportService.login(integrationId, country, identifier, persist, fields);
  };

  const fetchCompanies = async (sessionId: string | undefined) => {
    const result = await directImportService.getCompanies(sessionId);
    companies.value = result;
    return result;
  };

  const fetchTrialBalanceEntries = (
    sessionId: string | undefined,
    company: string | undefined,
    index: string | undefined,
    monthly: string | undefined,
    autoMap: boolean | undefined,
    removeEmptyAccounts: boolean | undefined,
    openingBalanceCheck: boolean | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    return directImportService.getTrialBalanceEntries(
      sessionId,
      company,
      index,
      monthly,
      autoMap,
      removeEmptyAccounts,
      openingBalanceCheck,
      startDate,
      endDate
    );
  };

  const fetchStatus = async (sessionId: string | undefined) => {
    const result = await directImportService.getStatus(sessionId);
    sessionStatus.value = result;
    return result;
  };

  const fetchGeneralLedgerEntries = (sessionId: string | undefined, data: any) => {
    return directImportService.getGeneralLedgerEntries(sessionId, data);
  };

  const saveJournals = (sessionId: string | undefined, companyId: string | undefined, journals: JournalEntry[]) => {
    return directImportService.postJournals(sessionId, companyId, journals);
  };

  const saveAutoLink = (country: string, index: string, data: any) => {
    return directImportService.autoLink(country, index, data);
  };

  const state = { companies, sessionStatus, directImportConnection, integrations };
  const getters = { connected, connectedIntegration, xeroConnectionBeta };
  const actions = {
    canConnect,
    canDrillDown,
    fetchIntegrations,
    fetchClientConnection,
    disconnect,
    openDirectImports,
    validateConnection,
    createConnection,
    login,
    fetchCompanies,
    fetchStatus,
    fetchGeneralLedgerEntries,
    fetchTrialBalanceEntries,
    saveJournals,
    saveAutoLink
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDirectImportStore, import.meta.hot));
}
