import { useDxModal } from '@repo/vue/utils';
import { DxModal } from '@repo/vue/components';

export const useDxConfirm = () => {
  const dialog = useDxModal();

  const show = (
    type?: string,
    header?: string,
    message?: string,
    btnOk?: string,
    btnCancel?: string,
    size?: string,
    helpRoute?: string
  ) => {
    const modalProps = {
      size: size || 'md',
      backdrop: 'static',
      class: 'dialog-sm',
      type: type,
      header: header,
      html: message,
      btnOk: btnOk,
      btnCancel: btnCancel,
      helpRoute: helpRoute
    };
    return dialog.prompt(DxModal, modalProps);
  };

  const information = (
    header?: string,
    message?: string,
    btnOk?: string,
    btnCancel?: string,
    size?: string,
    helpRoute?: string
  ) => {
    return show('info', header, message, btnOk, btnCancel, size, helpRoute);
  };

  const warning = (
    header?: string,
    message?: string,
    btnOk?: string,
    btnCancel?: string,
    size?: string,
    helpRoute?: string
  ) => {
    return show('warning', header, message, btnOk, btnCancel, size, helpRoute);
  };

  const error = (
    header?: string,
    message?: string,
    btnOk?: string,
    btnCancel?: string,
    size?: string,
    helpRoute?: string
  ) => {
    return show('error', header, message, btnOk, btnCancel, size, helpRoute);
  };

  const confirmation = (
    header?: string,
    message?: string,
    btnOk?: string,
    btnCancel?: string,
    size?: string,
    helpRoute?: string
  ) => {
    return show('confirm', header, message, btnOk, btnCancel, size, helpRoute);
  };

  return {
    information,
    warning,
    error,
    confirmation
  };
};
