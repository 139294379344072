import { EventBusListener, useEventBus } from '@vueuse/core';

export const useDxEventBus = (key = 'eventBus') => {
  // utils
  const eventBus = useEventBus<string>(key);

  // data
  const emit = (event?: string, payload?: any) => {
    eventBus.emit(event, payload);
  };

  const on = (listener: EventBusListener<string>) => {
    return eventBus.on(listener);
  };

  const off = (listener: EventBusListener<string>) => {
    eventBus.off(listener);
  };

  const once = (listener: EventBusListener<string>) => {
    return eventBus.once(listener);
  };

  const reset = () => {
    eventBus.reset();
  };

  return { emit, on, off, once, reset };
};
