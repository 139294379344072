import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { usePracticeService } from '@repo/core/services';
import { Practice } from '@repo/core/models';
import * as _ from 'lodash-es';

export const usePracticeStore = defineStore('practice', () => {
  // services
  const practiceService = usePracticeService();

  // state
  const currentPractice = ref<Practice>(<Practice>{});
  const practices = ref<Practice[]>([]);
  const practicesByFilter = ref<Practice[]>([]);

  // events
  const reloadPractices = ref<boolean>(false);

  // actions
  const fetchPractices = async () => {
    const result = await practiceService.get();
    practices.value = result;
    return result;
  };
  const fetchPracticesByFilter = async (parameters?: string) => {
    const result = await practiceService.get(parameters);
    practicesByFilter.value = result;
    return result;
  };
  const fetchPracticeById = async (id: string) => {
    const result = await practiceService.getById(id);
    currentPractice.value = result;
    return result;
  };
  const fetchCurrentPractice = async () => {
    const result = await practiceService.getCurrent();
    currentPractice.value = result;
    return result;
  };
  const fetchPracticeStatementPdf = (fromDate: Date | undefined, toDate: Date | undefined) => {
    return practiceService.getPracticeStatementPdf(fromDate, toDate);
  };
  const fetchPracticeStatementExcel = (fromDate: Date | undefined, toDate: Date | undefined) => {
    return practiceService.getPracticeStatementExcel(fromDate, toDate);
  };
  const savePractices = async (data: Practice[], options?: any) => {
    const result = await practiceService.post(data, options);
    const practice = _.find(
      result,
      (p: Practice) => () => _.toLower(currentPractice.value.id) === _.toLower(p.id)
    ) as Practice;
    if (practice) {
      currentPractice.value = practice;
    }
    return result;
  };

  const state = { practices, practicesByFilter, currentPractice, reloadPractices };
  const actions = {
    fetchPractices,
    fetchPracticesByFilter,
    fetchPracticeById,
    fetchCurrentPractice,
    fetchPracticeStatementPdf,
    fetchPracticeStatementExcel,
    savePractices
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePracticeStore, import.meta.hot));
}
