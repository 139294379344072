export const MessageConstants = {
  init: 'init',
  login: 'login',
  logout: 'logout',
  loadPage: 'loadPage',
  loadTab: 'loadTab',
  closeTab: 'closeTab',
  openModal: 'openModal',
  reloadClientMenu: 'reloadClientMenu',
  financialYearsChanged: 'financialYearsChanged',
  selectedYearChanged: 'selectedYearChanged',
  accountLinkComplete: 'accountLinkComplete'
};
