import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useUserService } from '@repo/core/services';
import { User } from '@repo/core/models';

export const useUserStore = defineStore('user', () => {
  const userService = useUserService();

  // state
  const currentUser = ref<User>(<User>{});
  const users = ref<User[]>([]);
  const usersByFilter = ref<User[]>([]);
  const searchResult = ref<any>();

  // getters
  const userRole = computed(() => (currentUser.value?.id ? Number(currentUser.value?.role) : 0));
  const isClerk = computed(() => userRole.value >= 0 && userRole.value < 100);
  const isManager = computed(() => userRole.value >= 100 && userRole.value < 200);
  const isPartner = computed(() => userRole.value >= 200);

  // actions
  const fetchCurrentUser = async () => {
    const result = await userService.getCurrent();
    currentUser.value = result;
    return result;
  };
  const fetchUsers = async () => {
    const result = await userService.get('?$expand=clients');
    users.value = result;
    return result;
  };
  const fetchUsersByFilter = async (parameters?: string) => {
    const result = await userService.get(parameters);
    usersByFilter.value = result;
    return result;
  };
  const saveUsers = (data: User[]) => {
    return userService.post(data);
  };
  const updateCurrentUser = async (data: User) => {
    const result = await userService.updateCurrent(data);
    currentUser.value = result;
    return result;
  };
  const searchUsers = async (email: string) => {
    const result = await userService.search(email);
    searchResult.value = result;
    return result;
  };
  const deleteUsers = (data: string[]) => {
    return userService.deleteUsers(data);
  };

  const state = { currentUser, users, usersByFilter, searchResult };
  const getters = { userRole, isClerk, isManager, isPartner };
  const actions = {
    fetchCurrentUser,
    fetchUsers,
    fetchUsersByFilter,
    saveUsers,
    updateCurrentUser,
    searchUsers,
    deleteUsers
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
