import { api } from '@repo/core/config';
import { RibbonItem } from '@repo/core/models';

export const useRibbonItemService = () => {
  const get = async (): Promise<RibbonItem[]> => {
    try {
      const response = await api.get('ribbonItems');
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  return { get };
};
