import * as _ from 'lodash-es';
import { api } from '@repo/core/config';
import { ResourceConfig } from '@repo/core/resources';
import { JournalEntry } from '@repo/core/models';

export const useDirectImportService = () => {
  const serverBase = ResourceConfig.directImportServerBase;

  const getIntegrations = async () => {
    try {
      const response = await api.get(`${serverBase}integrations`);

      const result = _.map(response.data, (c: any) => {
        c.$image = atob(c.image);
        c.$icon = atob(c.icon);
        return c;
      });
      response.data = [...result];

      return response.data;
    } catch (err) {
      console.error(err);
      return {
        success: false,
        response: err
      };
    }
  };

  const login = async (
    integrationId: string | undefined,
    country: string | undefined,
    identifier: string | undefined,
    persist: boolean | undefined,
    fields: any
  ) => {
    try {
      const response = await api.post(
        `${serverBase}integrations/authorize/${integrationId}?productId=418CF68E-4B09-4C8B-B376-3B0E2ACEDA5D&identifier=${identifier}&country=${country}&persist=${persist}`,
        fields
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCompanies = async (sessionId: string | undefined) => {
    try {
      const response = await api.get(`${serverBase}companies/${sessionId}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getStatus = async (sessionId: string | undefined) => {
    try {
      const response = await api.get(`${serverBase}integrations/status/${sessionId}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getTrialBalanceEntries = async (
    sessionId: string | undefined,
    company: string | undefined,
    index: string | undefined,
    monthly: string | undefined,
    autoMap: boolean | undefined,
    removeEmptyAccounts: boolean | undefined,
    openingBalanceCheck: boolean | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    try {
      const response = await api.post(`${serverBase}trialBalanceEntries/${sessionId}`, {
        sessionId: sessionId,
        company: company,
        monthly: monthly,
        index: index,
        autoMap: autoMap,
        removeEmptyAccounts: removeEmptyAccounts,
        openingBalanceCheck: openingBalanceCheck,
        startDate: startDate,
        endDate: endDate
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getGeneralLedgerEntries = async (sessionId: string | undefined, data: any) => {
    try {
      const response = await api.get(`${serverBase}generalLedgerEntries/${sessionId}`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const autoLink = async (country: string, index: string, data: any) => {
    try {
      const response = await api.post(
        `${serverBase}autoMap?country=${encodeURIComponent(country)}$index=${encodeURIComponent(index)}`,
        data
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const disconnect = async (sessionId: string | undefined, companyId: string | undefined) => {
    try {
      const response = await api.post(`${serverBase}integrations/disconnect/${sessionId}?companyId=${companyId}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const postJournals = async (
    sessionId: string | undefined,
    companyId: string | undefined,
    journals: JournalEntry[]
  ) => {
    try {
      const response = await api.post(`${serverBase}journalEntry/${sessionId}?companyId=${companyId}`, journals);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const validate = async (sessionId: string | undefined, companyId: string | undefined) => {
    try {
      const response = await api.post(`${serverBase}validate/${sessionId}?companyId=${companyId}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getIntegrations,
    login,
    getCompanies,
    getStatus,
    getTrialBalanceEntries,
    getGeneralLedgerEntries,
    autoLink,
    disconnect,
    postJournals,
    validate
  };
};
