import { closeModal, Modal, openModal, popModal, promptModal, pushModal } from 'jenesius-vue-modal';
import { ModalOptions } from 'jenesius-vue-modal/dist/types/utils/Modal';

export const useDxModal = () => {
  const open = (component: any, props?: any): Promise<Modal> => {
    return openModal(component, props);
  };

  const prompt = (component: any, props?: any, options?: Partial<ModalOptions>): Promise<unknown> => {
    return promptModal(component, props, options);
  };

  const push = (component: any, props?: any): Promise<Modal> => {
    return pushModal(component, props);
  };

  const pop = (): Promise<void> => {
    return popModal();
  };

  const close = (): Promise<void> => {
    return closeModal();
  };

  const closeById = (id: string): Promise<void> => {
    return closeById(id);
  };

  return {
    open,
    prompt,
    push,
    pop,
    close,
    closeById
  };
};
